import { createHttpEndpoint } from '../../utils'

import type {
  CreatePaymentResponse,
  GetEncryptionTokenResponse,
  GetLoanSimulation,
  GetNextActionResponse,
  GetPaymentMethods,
  PostConfirmCardResponse,
  PostPaymentApplePaySession,
} from './payment.types'

/**
 * Create a payment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/createPayment}
 */
export const postCreate = createHttpEndpoint<CreatePaymentResponse>({
  method: 'POST',
  operationId: 'paymentCreate',
  path: '/bm/payment/create',
  transformResponseToCamelCase: true,
})

/**
 * Refresh a payment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v2-api/definition#/default/processDuePayments}
 */
export const postRefresh = createHttpEndpoint<CreatePaymentResponse>({
  method: 'POST',
  operationId: 'paymentRefresh',
  path: '/bm/insurances/v2/policies/:policyId/due-payments',
  transformResponseToCamelCase: true,
  transformRequestToSnakeCase: true,
})

/**
 * Get result info from a payment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/resultPayment}
 */
export const getResult = createHttpEndpoint({
  method: 'GET',
  operationId: 'paymentResult',
  path: '/payment/payment_result/:paymentId',
  transformRequestToSnakeCase: true,
  transformResponseToCamelCase: true,
})

/**
 * Get eligible payment methods
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/getPaymentMethods}
 */
export const getMethods = createHttpEndpoint<GetPaymentMethods.Response>({
  method: 'GET',
  operationId: 'getPaymentMethods',
  path: '/payment/payment_methods',
  transformRequestToSnakeCase: true,
  transformResponseToCamelCase: true,
})

/**
 * Simulate a loan
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/loanSimulation}
 */
export const getLoanSimulation = createHttpEndpoint<GetLoanSimulation.Response>(
  {
    method: 'GET',
    operationId: 'fetchInstallmentSimulation',
    path: '/bm/loan-simulation',
    transformResponseToCamelCase: true,
  },
)

/**
 * Authorize payment through Adyen
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/adyenAuthorise}
 *
 * TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
 */
export const postAuthorize = createHttpEndpoint({
  method: 'POST',
  operationId: 'paymentAuthorize',
  path: '/payment/authorise/:paymentId',
  // TODO [PAYIN-2621] Add support for FormData
  // isFormData: true,
})

/**
 * Get payment and fraud provider public encryption keys
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/getPaymentToken}
 */
export const getEncryptionToken =
  createHttpEndpoint<GetEncryptionTokenResponse>({
    method: 'GET',
    operationId: 'paymentToken',
    path: '/payment/get-token',
    transformResponseToCamelCase: true,
  })

/**
 * Get payment details
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/getPaymentDetails}
 */
export const getDetails = createHttpEndpoint({
  method: 'GET',
  operationId: 'getPaymentDetails',
  path: '/payment/:paymentId/details',
})

/**
 * Create an Apple Pay payment session
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/createApplePayPaymentSession}
 */
export const postApplePaySession =
  createHttpEndpoint<PostPaymentApplePaySession>({
    method: 'POST',
    operationId: 'paymentApplePaySession',
    path: '/payment/applepay/session',
  })

/**
 * Get the next action for the given payment_id
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/nextAction}
 */
export const getNextAction = createHttpEndpoint<GetNextActionResponse>({
  method: 'GET',
  operationId: 'paymentNextAction',
  path: '/payment/next-action/v1/:paymentId',
})

/**
 * Confirm card payment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/scroogeAuthorizeCard}
 */
export const postConfirmCard = createHttpEndpoint<PostConfirmCardResponse>({
  method: 'POST',
  operationId: 'paymentConfirmCard',
  path: '/payment/confirm/card/:paymentId',
})

/**
 * Confirm APM payment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition#tag/payment/operation/AuthorizeApm}
 */
export const postConfirmHpp = createHttpEndpoint({
  method: 'POST',
  operationId: 'paymentConfirmHPP',
  path: '/payment/confirm/hpp/:paymentId',
})
